const STATE = {
  DRAWER: {
    OPEN: 'open',
    CLOSED: 'closed',
  },
  OVERLAY: {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
  },
  MEGAMENU: {
    CLOSED: 0,
    OPEN: 1,
  },
  DESCRIPTION: {
    CLOSED: 'closed',
    OPEN: 'open',
  },
  POSITIVITY: {
    CLOSED: 'hidden',
    OPEN: 'visible',
  },
};

export default STATE;
